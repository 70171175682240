/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

//SSH =>  npm run dev
//SSH =>  npm run watch

// any CSS you import will output into a single css file (app.css in this case)
import './styles/scss/base.scss';

// JS
import './javascript/all.js';
import './javascript/hde-custom.js';
import { fetchEventSource } from '@microsoft/fetch-event-source';
window.fetchEventSource = fetchEventSource;

// start the Stimulus application
import './bootstrap';

///// CHART JS
import ChartDataLabels from 'chartjs-plugin-datalabels';
document.addEventListener('chartjs:init', function (event) {
    const Chart = event.detail.Chart;
    Chart.register(ChartDataLabels);
});
