$( document ).ready(function() {

	/* Bootstrap Tooltips Initialiaztion */
	var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
	var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
		return new bootstrap.Tooltip(tooltipTriggerEl)
	});

	/* Bootstrap 5 Reinitialization after htmx */
	document.body.addEventListener("htmx:afterSwap", function () {
		var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
		var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
			return new bootstrap.Tooltip(tooltipTriggerEl);
		});
	});

	let initialized = new Set();
	$('textarea.tinymce').one('focus', function () {
		let textareaId = $(this).attr('id');

		if (!initialized.has(textareaId)) {
			tinymce.init({
				selector: `#${textareaId}`,
				height: 500,
				plugins: 'code preview autoresize importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
				editimage_cors_hosts: ['picsum.photos'],
				menubar: '',
				toolbar: 'undo redo | fullscreen template | bold italic underline strikethrough | blocks | alignleft aligncenter alignright | outdent indent | numlist bullist | code emoticons | image media link',
				toolbar_sticky: true,
				template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
				template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
				image_caption: true,
				quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
				noneditable_class: 'mceNonEditable',
				toolbar_mode: 'sliding',
				contextmenu: 'link image table',
				content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
				language: 'de',
				browser_spellcheck: true,
				automatic_uploads: true,
				images_upload_url: '/api/v3/upload-img/',
				extended_valid_elements: 'img[class|src|border|alt|title|hspace|vspace|width|height|align|onmouseover|onmouseout|name|loading=lazy]',
			});

			initialized.add(textareaId);
		}
	});

	$(".open-all-links").click(function() {
		var selected = [];
		$('.usefull-links .form-check-input:checked').each(function() {
			selected.push($(this).next().attr('href'));
		});

		//remove empty
		selected = selected.filter(item => item);

		jQuery.each(selected, function( i, val ) {
		  window.open(val);
		});
	});

	$(".usefull-links .list-group-item:nth-child(2) input:checkbox").click(function () {
		$(this).closest(".usefull-links").find("input:checkbox").prop('checked', $(this).prop('checked'));
	});

	$(".help-text-toggle").click(function() {
		$(".help-text").show();
	});

	$("#dashboard-links a").each(function(){
		var url = $(this).attr("href");
		$(this).attr("href", url + "?dashboard-link");
	});
});